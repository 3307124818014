* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	line-height: 1.55em;
}

:root {
	font-size: calc(0.65em + 1vw);
	--bg-color: #fafafa;
	--color: #1a1a1a;
}

[data-theme='dark'] {
	--bg-color: #1a1a1a;
	--color: #fafafa;
}

html,
body,
#root {
	height: 100%;
	background-color: var(--bg-color);
}

a {
	color: var(--color);
	border-bottom: 1px dashed hsla(0, 0%, 50%, 0.67);
	text-decoration: none;
}

article {
	display: flex;
	position: relative;
	flex-direction: column;
	max-width: 30em;
	padding: 0 1em;
	margin: 0 auto;
	color: var(--color);
	background-color: var(--bg-color);
	font-family: Georgia, 'Times New Roman', Times, serif;
}

header {
	padding-bottom: 0.5em;
	font-size: 1.67em;
	text-align: center;
	font-family: Helvetica, sans-serif;
}

article>header {
	padding-top: 2em;
	font-size: 2em;
	font-family: Georgia, 'Times New Roman', Times, serif;
}

main {
	text-align: justify;
	padding-bottom: 2em;
}

section {
	padding-bottom: 2em;
}

section#big {
	margin: 0 2em;
}

section#small {
	text-align: center;
	padding-bottom: 3em;
}

#big ul {
	list-style: none;
}

#big li {
	display: flex;
	align-items: center;
	margin-bottom: 1.25em;
}

svg {
	flex-shrink: 0;
	padding-right: 1em;
	height: auto;
	width: 4.5em;
}

path {
	fill: var(--color);
}

#office-icon {
	padding-top: 0.5em;
	/* eyeballed */
}

.project-title {
	font-weight: bolder;
	font-family: sans-serif;
	margin-right: 0.5em;
	font-size: 1.1em;
}

#medium ul {
	margin-left: 3em;
	margin-right: 1em;
}

#medium li {
	margin-bottom: 1.25em;
}

section#small>span>span {
	opacity: 0.5;
	padding: 0 0.33em;
}

#small>span:first-of-type span {
	display: none;
}

footer ol {
	font-size: 0.9em;
	padding-bottom: 6em;
	padding-left: 2em;
}

footer li {
	padding-bottom: 0.5em;
}

#email {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	left: 0;
	bottom: 0;
	right: 0;
	height: 5em;
	padding: 0.75em 1.5em;
	background-color: var(--bg-color);
	box-shadow: 0 0 1em 1em var(--bg-color);
	font-family: Helvetica, sans-serif;
}

#email>a {
	border: none;
	font-size: 1.1em;
	font-family: monospace;
}

#at {
	display: inline-block;
	position: relative;
	font-size: 1em;
	padding: 0 0.05em;
	font-family: Helvetica, sans-serif;
}

#toggle-slot {
	background-color: var(--color);
	width: 3.5em;
	height: 2em;
	border-radius: 1em;
	display: flex;
	align-items: center;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
}

#toggle-slot>input {
	display: none;
}

#toggle-btn {
	background-color: var(--bg-color);
	width: 1.5em;
	height: 1.5em;
	margin: 0.25em;
	border-radius: 0.75em;
	transition: 0.125s;
}

input:checked+#toggle-btn {
	transform: translateX(1.5em);
}

@media (min-width: 1000px) {
	#email {
		background-color: unset;
		box-shadow: unset;
	}
}